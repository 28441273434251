import groupApi from '../../data-access/group-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const groupFetcher = createFetcherScope(groupApi);
export const fetchGroup = groupFetcher.createFetcher(groupApi.fetchGroup);
export const fetchGroups = groupFetcher.createFetcher(groupApi.searchGroups);
export const fetchGroupHistory = groupFetcher.createFetcher(groupApi.fetchGroupHistory);

export const fetchExternalGroupApplications = groupFetcher.createFetcher(groupApi.fetchExternalGroupApplications);

export const relatedFilmFetcher = createFetcherScope(groupApi);
export const searchGroupFilmRelationListItems = relatedFilmFetcher.createFetcher(
  groupApi.searchGroupFilmRelationListItems,
);
export const fetchFilmHistory = relatedFilmFetcher.createFetcher(groupApi.fetchGroupFilmHistory);
