/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  CreateGroupFilmRelationDto,
  FailureDto,
  GroupDto,
  GroupExternalApplicationPageDto,
  GroupFilmPageDto,
  GroupPageDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CreateGroupFilmRelationDtoFromJSON,
    CreateGroupFilmRelationDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupDtoFromJSON,
    GroupDtoToJSON,
    GroupExternalApplicationPageDtoFromJSON,
    GroupExternalApplicationPageDtoToJSON,
    GroupFilmPageDtoFromJSON,
    GroupFilmPageDtoToJSON,
    GroupPageDtoFromJSON,
    GroupPageDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateGroupRequest {
    groupDto: GroupDto;
}

export interface CreateGroupFilmRelationRequest {
    createGroupFilmRelationDto: CreateGroupFilmRelationDto;
}

export interface DeleteGroupByIdRequest {
    id: string;
}

export interface DeleteGroupFilmRelationRequest {
    relationId: string;
}

export interface FetchExternalGroupApplicationsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface FetchGroupRequest {
    id: string;
}

export interface FetchGroupFilmHistoryRequest {
    groupId?: string;
    pageable?: PageableDto;
}

export interface FetchGroupHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface GroupDeletableRequest {
    id: string;
}

export interface GroupFilmRelationExistsRequest {
    groupId: string;
    filmId: string;
}

export interface SearchGroupFilmRelationListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchGroupsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    focusedEditionId?: string;
}

export interface TemplateGroupRequest {
    groupTemplateId: string;
}

export interface UpdateGroupRequest {
    id: string;
    groupDto: GroupDto;
}

/**
 * 
 */
export class GroupApi extends runtime.BaseAPI {

    /**
     * Create a new group.
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['groupDto'] == null) {
            throw new runtime.RequiredError(
                'groupDto',
                'Required parameter "groupDto" was null or undefined when calling createGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupDtoToJSON(requestParameters['groupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Create a new group.
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a group film relation.
     */
    async createGroupFilmRelationRaw(requestParameters: CreateGroupFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createGroupFilmRelationDto'] == null) {
            throw new runtime.RequiredError(
                'createGroupFilmRelationDto',
                'Required parameter "createGroupFilmRelationDto" was null or undefined when calling createGroupFilmRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/group-film-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGroupFilmRelationDtoToJSON(requestParameters['createGroupFilmRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a group film relation.
     */
    async createGroupFilmRelation(requestParameters: CreateGroupFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createGroupFilmRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete group by id
     */
    async deleteGroupByIdRaw(requestParameters: DeleteGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteGroupById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete group by id
     */
    async deleteGroupById(requestParameters: DeleteGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete group-film relation.
     */
    async deleteGroupFilmRelationRaw(requestParameters: DeleteGroupFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling deleteGroupFilmRelation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['relationId'] != null) {
            queryParameters['relationId'] = requestParameters['relationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/group-film-relations`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete group-film relation.
     */
    async deleteGroupFilmRelation(requestParameters: DeleteGroupFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupFilmRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Get external applications of group.
     */
    async fetchExternalGroupApplicationsRaw(requestParameters: FetchExternalGroupApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupExternalApplicationPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchExternalGroupApplications().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/external-applications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupExternalApplicationPageDtoFromJSON(jsonValue));
    }

    /**
     * Get external applications of group.
     */
    async fetchExternalGroupApplications(requestParameters: FetchExternalGroupApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupExternalApplicationPageDto> {
        const response = await this.fetchExternalGroupApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the group template business object.
     */
    async fetchGroupRaw(requestParameters: FetchGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Get the group template business object.
     */
    async fetchGroup(requestParameters: FetchGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.fetchGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchGroupFilmHistoryRaw(requestParameters: FetchGroupFilmHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['groupId'] != null) {
            queryParameters['groupId'] = requestParameters['groupId'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/film-relation-versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchGroupFilmHistory(requestParameters: FetchGroupFilmHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupFilmHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the group.
     */
    async fetchGroupHistoryRaw(requestParameters: FetchGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the group.
     */
    async fetchGroupHistory(requestParameters: FetchGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current deletability of the group.
     */
    async groupDeletableRaw(requestParameters: GroupDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling groupDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/can-delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the current deletability of the group.
     */
    async groupDeletable(requestParameters: GroupDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.groupDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the given group-film relation already exists
     */
    async groupFilmRelationExistsRaw(requestParameters: GroupFilmRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling groupFilmRelationExists().'
            );
        }

        if (requestParameters['filmId'] == null) {
            throw new runtime.RequiredError(
                'filmId',
                'Required parameter "filmId" was null or undefined when calling groupFilmRelationExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['groupId'] != null) {
            queryParameters['groupId'] = requestParameters['groupId'];
        }

        if (requestParameters['filmId'] != null) {
            queryParameters['filmId'] = requestParameters['filmId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/group-film-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the given group-film relation already exists
     */
    async groupFilmRelationExists(requestParameters: GroupFilmRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.groupFilmRelationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group-film relation list items.
     */
    async searchGroupFilmRelationListItemsRaw(requestParameters: SearchGroupFilmRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupFilmPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/group-film-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFilmPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group-film relation list items.
     */
    async searchGroupFilmRelationListItems(requestParameters: SearchGroupFilmRelationListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupFilmPageDto> {
        const response = await this.searchGroupFilmRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for groups.
     */
    async searchGroupsRaw(requestParameters: SearchGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['focusedEditionId'] != null) {
            queryParameters['focusedEditionId'] = requestParameters['focusedEditionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for groups.
     */
    async searchGroups(requestParameters: SearchGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupPageDto> {
        const response = await this.searchGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new group.
     */
    async templateGroupRaw(requestParameters: TemplateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['groupTemplateId'] == null) {
            throw new runtime.RequiredError(
                'groupTemplateId',
                'Required parameter "groupTemplateId" was null or undefined when calling templateGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['groupTemplateId'] != null) {
            queryParameters['groupTemplateId'] = requestParameters['groupTemplateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new group.
     */
    async templateGroup(requestParameters: TemplateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.templateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing group.
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroup().'
            );
        }

        if (requestParameters['groupDto'] == null) {
            throw new runtime.RequiredError(
                'groupDto',
                'Required parameter "groupDto" was null or undefined when calling updateGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupDtoToJSON(requestParameters['groupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing group.
     */
    async updateGroup(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.updateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
